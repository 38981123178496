import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { CalculatedInput } from './CalculatedInputExtension';

export const CalculatedInputExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CalculatedInput',
    component: CalculatedInput,
  }),
);
