import {
  IdentityProviders,
  SignInProviderConfig,
} from '@backstage/core-components';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';

export const getAvailableIdentityProviders = (config: Config) => {
  const authConfig = config.getOptionalConfig('auth');
  if (!authConfig) {
    return ['guest'] as IdentityProviders;
  }

  const authEnv = config.getString('auth.environment');
  const azureAuth = config.getOptionalConfig('auth.providers.microsoft');

  const azureProviderConfig: SignInProviderConfig | undefined = azureAuth
    ? {
        id: 'azure-auth-provider',
        title: 'Microsoft Azure',
        message: 'Sign in using Microsoft Azure',
        apiRef: microsoftAuthApiRef,
      }
    : undefined;

  const guestAuthConfig = ['local', 'development'].includes(authEnv)
    ? 'guest'
    : null;

  return [guestAuthConfig, azureProviderConfig].filter(
    c => !!c,
  ) as IdentityProviders;
};
