import template from 'lodash/template'
import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const CalculatedInput = (props: FieldExtensionComponentProps<string>) => {
  const {
    onChange,
    rawErrors,
    required,
    formContext,
    formData,
    idSchema,
    schema,
    uiSchema,
  } = props;

  const valueTemplate = template(uiSchema['ui:calculatedTemplate']||'')
  const calculatedValue = valueTemplate({form: formContext.formData})
  useEffect(() => onChange(calculatedValue), [onChange, calculatedValue]);

  return (
    <TextField
      id={idSchema?.$id}
      label={schema.title}
      InputLabelProps={{ shrink: true }}
      helperText={schema.description}
      required={required}
      disabled={uiSchema['ui:disabled']}
      value={calculatedValue}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus: false }}
      FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
    />
  );
};
